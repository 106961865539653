import { Avatar } from "./avatar";
import ReliabilityRank from "./reliability-rank";
import React from "react";
import {Badge} from "./badge";
import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from "./table";
import getStatusColor from '../shared/utils/get-status-color';
import dayjs from 'dayjs';
type ClinicianCondensedProfileRowsProps = {
  openings: Opening[];
};

// TODO: Make sure you fix the overflow on mobile!

const formatTime = (time: string | undefined) => {
  if (!time) {
    return ''
  }
  return dayjs(time).utc().format('hh:mm A')
}
const ClinicianCondensedProfileRows: React.FC<ClinicianCondensedProfileRowsProps> = ({ openings }) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return (<div className="mt-4">
    <Table>
        <TableHead>
          <TableRow>
            <TableHeader>Name</TableHeader>
            <TableHeader>Status</TableHeader>
            <TableHeader>Scheduled Time</TableHeader>
            <TableHeader>Clock In Time</TableHeader>
            <TableHeader>Clock Out Time</TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {openings.map((o) => {
            const firstname = o.clinicians?.people.firstname ?? ''
           return (<TableRow key={o.opening_id}>
              <TableCell>
                <div className="flex items-center gap-4">
                  {o.clinicians && <>
                    <Avatar initials={firstname[0]} square={true} className="size-12" />
                  <div>
                    <div className="font-medium">{o.clinicians?.people.firstname} {o.clinicians?.people.lastname}</div>
                    <div className="mt-1 text-zinc-500">
                      <a href="#" className="hover:text-zinc-700">
                        <ReliabilityRank reliability={o.clinicians.reliability}/>
                      </a>
                    </div>
                  </div>
                  </>
                  }
                </div>
              </TableCell>
              <TableCell>
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                { /* @ts-ignore **/ }
                {o.status && <Badge color={getStatusColor(o.status)}>{o.status}</Badge>}
              </TableCell>
              <TableCell>
                {formatTime(o.start_time)} - {formatTime(o.end_time)}
              </TableCell>
              <TableCell>
                {formatTime(o.reported_clock_in)}
              </TableCell>
              <TableCell>
                {formatTime(o.reported_clock_out)}
              </TableCell>
            </TableRow>)
          })}
        </TableBody>
      </Table>
  </div>);
}

export default ClinicianCondensedProfileRows;