import {
  CheckBadgeIcon,
  ClipboardDocumentCheckIcon,
  ExclamationTriangleIcon,
  NoSymbolIcon
} from "@heroicons/react/24/solid";
// import BillingSummary from "./billing-summary";
import TodaysSchedule from "./todays-schedule";
import { useApiUrl, useCustom } from '@refinedev/core';
import StatCard from './stat-card';

interface StatLine {
  shifts: Shift[],
  count: number
}

interface DashboardStats {
  stats: {
    thisMonth: {
      completed: StatLine,
      confirmed: StatLine,
      open: StatLine,
      cancelled: StatLine,
    }
  },
  todaysSchedule: Opening[]
}
export default function Dashboard() {
  const apiUrl = useApiUrl();
  const { data, isLoading } = useCustom<DashboardStats>({
    url: `${apiUrl}/dashboard`,
    method: "get",
  });

  const thisMonth = data?.data.stats.thisMonth;
  const todaysSchedule = data?.data.todaysSchedule;

  return (
    <div>
      <h3 className="text-base font-semibold leading-6 text-gray-900">Last 30 days</h3>
        {
          !isLoading && data?.data && thisMonth &&
          <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-1 lg:grid-cols-4">
            <StatCard title="Shifts Completed" value={thisMonth.completed.count} Icon={CheckBadgeIcon} color="bg-green-500"  />
            <StatCard title="Shifts Confirmed" value={thisMonth.confirmed.count} Icon={ClipboardDocumentCheckIcon} color="bg-blue-500"  />
            <StatCard title="Shifts Open" value={thisMonth.open.count} Icon={ExclamationTriangleIcon} color="bg-yellow-500"  />
            <StatCard title="Shift Cancellations" value={thisMonth.cancelled.count} Icon={NoSymbolIcon} color="bg-red-500"  />
          </dl>
        }
      {
        todaysSchedule &&
        <dl className="mt-5 grid grid-cols-1">
          <TodaysSchedule openings={todaysSchedule} />
        </dl>
      }
</div>
)
}
