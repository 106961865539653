import React from 'react';
import { Badge, Button, Card, Label } from 'flowbite-react';
import ClinicianProfileRow from '../../components/clinician-profile-row';
import { HttpError, useUpdate } from '@refinedev/core';
import { DocumentDuplicateIcon } from '@heroicons/react/24/solid';

type ApplicationProps = {
  application: Application;
  shift: Shift;
  isPastShift: boolean;
  refetchData: () => Promise<any>;
}

const ApplicationRow = ({application, shift, refetchData, isPastShift}: ApplicationProps) => {
  const { mutate: acceptShift } = useUpdate<Shift, HttpError>({
    resource: 'applications/accept',
    id: application.application_id,
    mutationOptions: {
      onSuccess: async () => {
        await refetchData();
      },
    }
  });

  const { mutate: rejectShift } = useUpdate<Shift, HttpError>({
    resource: 'applications/reject',
    id: application?.application_id,
    mutationOptions: {
      onSuccess: async () => {
        await refetchData();
      },
    }
  });

  const handleCopy = async () => {
    await navigator.clipboard.writeText(application.application_id);
  };

  return (
    <Card style={{ backgroundColor: '#fafafa', marginBottom: 12}}>
      <div className='flex grow justify-between gap-2'>
        <div className='flex-1'>
          <div className="flex items-center justify-between gap-2">
            <Button size="xs" onClick={handleCopy} color="light">
              <Label>Application ID</Label>
              <DocumentDuplicateIcon className="ml-2 size-4" />
            </Button>
            <Badge className="h-8 px-4" color={application.status === 'CANCELLED' ? 'red' : application.status === 'ACCEPTED' ? 'green' : 'blue'}>{application.status}</Badge>
          </div>
          <ClinicianProfileRow shift={shift} clinician={application.clinicians} refetchData={refetchData} />
        </div>
        <div>
        </div>
      </div>
      {
        !isPastShift && application.status !== 'REJECTED' && <div className='flex justify-end gap-2'>
          <Button color='red' onClick={() => rejectShift({values: {}})}>
            Reject
          </Button>
          {
            application.status == 'PENDING' && <Button color='blue' outline onClick={() => acceptShift({values: {}})}>
            Accept
          </Button>
          }
        </div>
      }
    </Card>
  )
}

export default ApplicationRow;