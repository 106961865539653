import React, { useState, useEffect } from 'react';
import ShiftDrawer from './shift-drawer';
import { HttpError, useList, useMany } from '@refinedev/core';
import Loading from '../../components/loading/loading';
import {
  Eventcalendar,
  MbscCalendarEvent, MbscEventClickEvent,
} from '@mobiscroll/react';
import generateEvents from '../../shared/utils/generate-events';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import ShiftManagementHeader from './shift-management-header';

const CalendarMonthView = () => {
  const navigate = useNavigate();
  const [openShiftDrawer, setOpenShiftDrawer] = useState<boolean>(false);
  const [eventData, setEventData] = useState<MbscCalendarEvent[]>([]);
  const { data: shifts, isLoading } = useList<Shift, HttpError>({
    resource: "shifts",
  });

  useEffect(() => {
    if (shifts) {
      const events = generateEvents(shifts.data);
      setEventData(events);
    }
  }, [shifts]);

  const navigateToEditView = (event: MbscEventClickEvent) => {
    const date = dayjs(event.event.customData.date).utc().format('YYYY-MM-DD');
    navigate({
      pathname: `/shift-management/day/${date}`,
    });
  };

  return isLoading ? <Loading />
    : <div className="flex flex-col">
      <ShiftManagementHeader setOpenShiftDrawer={setOpenShiftDrawer} />
      <>
        <Eventcalendar
          themeVariant="light"
          height="90vh"
          data={eventData}
          eventOverlap={false}
          view={{
            calendar: {
              popoverClass: 'calendar-popover-custom',
            }
          }}
          onEventClick={(event) => {
            navigateToEditView(event);
          }}
        />
      </>
      <ShiftDrawer open={openShiftDrawer} setOpen={setOpenShiftDrawer} />
    </div>
};

export default CalendarMonthView;