const getStatusColor = (status: string): "yellow" | "red" | "blue" | "green" | undefined => {
  switch (status) {
    case 'ACTIVE':
      return 'yellow';
    case 'SELECTED':
      return 'blue';
    case 'CANCELLED':
      return 'red';
    case 'CONFIRMED':
      return 'green';
  }
}

export default getStatusColor