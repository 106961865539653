import { ElementType} from 'react';

interface StatCardProps {
  title: string;
  value: number;
  Icon: ElementType;
  color: string;
}
const StatCard = ({ title, value, Icon, color }: StatCardProps) => {
  return (<div
    className="relative overflow-hidden rounded-lg bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6"
  >
    <dt>
      <div className={`absolute rounded-md ${color} p-3`}>
        <Icon className="size-6 text-white" />
      </div>
      <p className="ml-16 truncate text-sm font-medium text-gray-500">{title}</p>
    </dt>
    <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
      <p className="text-2xl font-semibold text-gray-900">{value}</p>
      {/*<p*/}
      {/*  className={classNames(*/}
      {/*    item.changeType === 'increase' ? 'text-green-600' : 'text-red-600',*/}
      {/*    'ml-2 flex items-baseline text-sm font-semibold',*/}
      {/*  )}*/}
      {/*>*/}
      {/*  {item?.changeType && (item.changeType === 'increase' ? (*/}
      {/*    <ArrowUpIcon aria-hidden="true" className="size-5 shrink-0 self-center text-green-500"/>*/}
      {/*  ) : (*/}
      {/*    <ArrowDownIcon aria-hidden="true" className="size-5 shrink-0 self-center text-red-500"/>*/}
      {/*  ))}*/}

      {/*  <span className="sr-only"> {item.changeType === 'increase' ? 'Increased' : 'Decreased'} by </span>*/}
      {/*  {item.change}*/}
      {/*</p>*/}
      {/*<div className="absolute inset-x-0 bottom-0 bg-gray-50 p-4 sm:px-6">*/}
      {/*  <div className="text-sm">*/}
      {/*    <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">*/}
      {/*      View all<span className="sr-only"> {title} stats</span>*/}
      {/*    </a>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </dd>
  </div>)
}

export default StatCard;