import { Avatar } from "./avatar";
import ReliabilityRank from "./reliability-rank";
import React, { useState } from 'react';
import { NoSymbolIcon  } from "@heroicons/react/24/solid";
import {Badge} from "./badge";
import { Subheading } from "./heading";
import { Button } from './buttons/button';
import { Text } from './text';
import { HttpError, useUpdate } from '@refinedev/core';
import { Simulate } from 'react-dom/test-utils';
import cancel = Simulate.cancel;
import { Modal } from 'flowbite-react';
import { Icon } from '@mobiscroll/react/dist/src/core/components/icon/icon.common';

type Props = {
  shift: Shift;
  clinician: Clinician;
  status?: string;
  isPastShift?: boolean;
  refetchData: () => Promise<any>;
};

const ClinicianProfileRow: React.FC<Props> = ({ shift, clinician, status, refetchData, isPastShift }) => {
  const opening = shift?.openings.find(o => o.assigned_clinician === clinician.clinician_id)
  const { mutate: cancelClinician } = useUpdate<Shift, HttpError>({
    resource: 'openings/facilityCancelClinician',
    id: opening?.opening_id,
    mutationOptions: {
      onSuccess: async () => {
        await refetchData();
      },
    }
  });

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'CONFIRMED':
        return 'green';
      case 'SELECTED':
        return 'blue';
      case 'CANCELLED':
        return 'red';
      default:
        return 'yellow';
    }
  }

  return (
    <li key={shift.shift_id} className="m-2 flex flex-col">
      <div className="mt-4 bg-white p-4 shadow sm:rounded-lg sm:p-6" >
        <div className="relative flex space-x-6 pb-4 xl:static">
          <Avatar initials="NF" className="size-14 flex-none" square/>
          <div className="flex-auto">
            <Text className="font-normal text-black">{clinician.people.firstname} {clinician.people.lastname}</Text>
            <Subheading level={6} className="">{shift.positions.position_name}</Subheading>
            <ReliabilityRank reliability={clinician.reliability}/>
          </div>
          { status && <div className="flex flex-row items-start">
            <Badge className="h-8 px-4" color={getStatusColor(status)}>{status}</Badge>
          </div> }
        </div>
        { !isPastShift &&
            <div className="flex flex-row gap-2">
              <div className="mr-auto gap-2">
                {/*<Button outline>*/}
                {/*  <UserIcon/>*/}
                {/*  <span className="mr-1">*/}
                {/*  View Profile*/}
                {/*</span>*/}
                {/*</Button>*/}
                {/*<Button outline>*/}
                {/*  <ChatBubbleBottomCenterIcon/>*/}
                {/*  <span className="mr-1">*/}
                {/*  Send Message*/}
                {/*</span>*/}
                {/*</Button>*/}
              </div>
            {
              (status === 'CONFIRMED' || status === 'SELECTED') && !opening?.immutable_clock_in && (
                <Button type="button" outline onClick={() => cancelClinician({values: {}})}>
                  <NoSymbolIcon/>
                  Cancel Clinician
                </Button>
              )
            }
          </div>
        }
      </div>
    </li>
);
}

export default ClinicianProfileRow;