import React from "react";
import Card from "../../components/card";
import ClinicianCondensedProfileRows from "../../components/clinician-condensed-profile-rows";

interface TodaysScheduleProps {
  openings: Opening[]
}

const TodaysSchedule: React.FC<TodaysScheduleProps> = ({
  openings
}) => {
  return (
    <Card title="Today's Schedule" noDivider={true}>
      {openings.length ? <ClinicianCondensedProfileRows openings={openings}/> : <div className="p-4 text-center text-gray-500">No shifts scheduled today</div>}
    </Card>
  )
}

export default TodaysSchedule;