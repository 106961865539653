import { Alert, Avatar, Button, Card, Checkbox, Modal } from 'flowbite-react';
import { Text } from '../../components/text';
import { useState } from 'react';
import { Badge } from '../../components/badge';
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import getStatusColor from '../../shared/utils/get-status-color';
import { HttpError, useUpdate } from '@refinedev/core';

interface ShiftCancellationModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  shift: Shift,
  refetchData: () => Promise<any>;
}

const ShiftCancellationModal = ({
  open,
  setOpen,
  shift,
  refetchData
}: ShiftCancellationModalProps) => {
  // update with use update and send cancellationlist as the variable to the api

  const [cancellationList, setCancellationList] = useState<string[]>([]);
  const { mutate: cancelShift, isLoading, isError, isSuccess } = useUpdate<Shift, HttpError>({
    resource: `shifts/cancel`,
    id: shift?.shift_id,
    values: cancellationList,
    mutationOptions: {
      onSuccess: async () => {
        onClose()
        await refetchData();
      },
    }
  });

  const handleSetCancellationList = (opening: Opening, checked: boolean) => {
    if (!checked && cancellationList.includes(opening.opening_id)) {
      setCancellationList(cancellationList.filter((id) => id !== opening.opening_id));
    } else {
      const newList = [...cancellationList, opening.opening_id]
      console.log(newList)
      setCancellationList(newList);
    }
  }

  const onClose = () => {
    setCancellationList([]);
    setOpen(false);
  }

  const handleProcessCancellations = () => {
    cancelShift();
  }

  return (
    <Modal show={open} size="2xl" onClose={() => setOpen(false)} popup>
      <Modal.Header className="items-end" style={{ margin: 12, marginLeft: 20 }}>
        <Text style={{ fontSize: 20 }}>Manage Shift Openings</Text>
      </Modal.Header>
      <Modal.Body className="pb-0">
        <Alert className="mb-8" color="red" icon={ExclamationTriangleIcon} style={{ padding: 14 }}>
          <Text>
            This action cannot be reversed. Any shifts that are cancelled will immediately remove the clinician/close any open applications.
          </Text>
        </Alert>
          {
            shift.openings.map((opening) => {
              const isCancelled = opening.status === "CANCELLED";
              return <Card className="mb-2" key={opening.opening_id}>
                <div className=" flex items-center justify-between">
                  <Checkbox color="red" defaultChecked={isCancelled} onChange={(select) => {
                    console.log(select.target.checked)
                    handleSetCancellationList(opening, select.target.checked)
                  }} disabled={isCancelled}/>
                  {!isCancelled && opening.clinicians ? (
                    <div className="flex items-center space-x-2">
                      <Avatar color="blue" placeholderInitials={opening.clinicians.people.firstname[0]}/>
                      <Text>{opening.clinicians.people.firstname} {opening.clinicians.people.lastname}</Text>
                    </div>
                  ) : (
                    <Text>{shift.applications.length} applications</Text>
                  )}
                  <Badge color={getStatusColor(opening.status) ?? 'zinc'}>{opening.status}</Badge>
                </div>
              </Card>
            })
          }
      </Modal.Body>
      <Modal.Footer style={{justifyContent: "end"}}>
        <Button color="red" onClick={handleProcessCancellations} disabled={shift.openings.filter(o => {
          return o.status !== 'CANCELLED' && cancellationList.includes(o.opening_id)
        }).length === 0}>Process Cancellations</Button>
        <Button color="blue" onClick={() => onClose()}>
          Nevermind
        </Button>
      </Modal.Footer>
  </Modal>
  );
}

export default ShiftCancellationModal;